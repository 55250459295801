import React, { FC, ReactNode } from "react";

type Background = "light" | "dark";
type Alignment = "left" | "center" | "right";

type Props = {
  background?: Background;
  alignment?: Alignment;
  children?: ReactNode;
};

const alignClassname = (alignment: Alignment): string => {
  switch (alignment) {
    case "left":
      return "items-start";
    case "center":
      return "items-center";
    case "right":
      return "items-end";
  }
};

const Card: FC<Props> = ({ alignment = "left", background = "light", children }) => (
  <div
    data-testid="card"
    className={`rounded-2xl border-gray-50 border-[1px] shadow-main py-2 px-4 flex flex-col ${alignClassname(
      alignment
    )} ${background == "light" ? "bg-white" : "bg-green text-white"}`}
  >
    {children}
  </div>
);

export default Card;
