import React from "react";
import Head from "next/head";

type Props = {
  title?: string;
};

export const DocumentHead: React.FC<Props> = ({ title }) => (
  <Head>
    <title>NOTYD | {title}</title>
    <link rel="icon" href="/favicon.ico" />
    <link rel="shortcut icon" href="/favicon.ico" />

    <meta name="description" content="Manage your payment requests, refunds, and more with the NOTYD Seller Portal" />
    <meta name="keywords" content="notyd seller portal, b2b payments dashboard" />

    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    <meta charSet="utf-8" />

    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  </Head>
);
