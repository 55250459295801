import { Http } from "api/http";
import { provider } from "api/http/provider";
import AuthManager from "api/auth/manager";
import { AuthError, CommonError, Token } from "./model/auth";
import axios, { ResponseType } from "axios";
import { CommonRequestResult } from "./model/common";
import { OrderCaptureRequest, OrderCaptureResponse } from "./model/order-capture";
import { OrderRefundRequest, OrderRefundResponse } from "./model/order-refund";
import getConfig from "next/config";
import getT from "next-translate/getT";
import { OrderRequestResponse } from "./model/order-request";
import { PaginationResults } from "./model/pagination";
import { SellerProfile } from "./model/seller-profile";
import { SellerPayment } from "./model/seller-payment";

class APIClient {
  private http: Http;

  constructor(http: Http) {
    this.http = http;
  }

  throwError = async (tKey: string, locale: string = "en") => {
    const t = await getT(locale, "error");
    throw Error(t(tKey));
  };

  // general purpose method to make http GET requests
  get = async <T>(
    url = "",
    params = {},
    responseType: ResponseType = "json",
    contentType = "application/json",
    locale?: string
  ) => {
    try {
      return await this.http.get<T>(url, params, responseType, contentType);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw err;
      }
    }
    await this.throwError("get", locale);
  };

  post = async <T>(
    url = "",
    data = {},
    responseType: ResponseType = "json",
    contentType = "application/json",
    locale?: string
  ) => {
    try {
      return await this.http.post<T>(url, data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          throw err;
        }
      }
      await this.throwError("post", locale);
    }
  };

  createSellerPayment = async (invoice_uuid: string, amount: number, bookDateTime: Date) => {
    return this.post<SellerPayment>(`/v1/api/invoices/${invoice_uuid}/seller-payments/`, {
      amount: amount,
      book_datetime: bookDateTime,
    });
  };

  login = async (username: string, password: string, locale?: string) => {
    try {
      const result = await this.http.post<Token>("/v1/api/token/", {
        username,
        password,
      });
      AuthManager.setToken(result.data.access);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const e = err.response?.data as AuthError;
          throw Error(e.detail);
        }
      }
      await this.throwError("login.network", locale);
    }
  };

  captureOrder = async (uuid: string, locale?: string, capture?: OrderCaptureRequest) => {
    try {
      const result = await this.http.post<OrderCaptureResponse>(`/v1/api/orders/${uuid}/capture/`, capture);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const e = err.response?.data as CommonError;
          if (e.details) {
            throw Error(e.details);
          } else {
            await this.throwError("captureOrder.system", locale);
          }
        }
      }
      await this.throwError("captureOrder.network", locale);
    }
  };

  cancelOrder = async (uuid: string, locale?: string) => {
    try {
      const result = await this.http.post<CommonRequestResult>(`/v1/api/orders/${uuid}/cancel/`);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const e = err.response?.data as CommonError;
          if (e.details) {
            throw Error(e.details);
          } else {
            await this.throwError("cancelOrder.system", locale);
          }
        }
      }
      await this.throwError("cancelOrder.network", locale);
    }
  };

  refundOrder = async (uuid: string, locale?: string, refund?: OrderRefundRequest) => {
    try {
      const result = await this.http.post<OrderRefundResponse>(`/v1/api/orders/${uuid}/refund/`, refund);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const e = err.response?.data as CommonError;
          if (e.details) {
            throw Error(e.details);
          } else {
            await this.throwError("refundOrder.system", locale);
          }
        }
      }
      await this.throwError("refundOrder.network", locale);
    }
  };

  orderRequest = async (data: object) => {
    try {
      const result = await this.http.post<OrderRequestResponse>(`/v1/api/order-request/`, data);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const e = err.response?.data as CommonError;
          if (e.details) {
            throw Error(e.details);
          } else {
            await this.throwError("common.system");
          }
        }
      }
      await this.throwError("common.network");
    }
  };

  getSellerProfiles = async () => {
    try {
      return await this.http.get<PaginationResults<SellerProfile>>("/v1/api/seller_profiles/");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response) {
          const e = err.response?.data as CommonError;
          if (err.response.status === 401) {
            return;
          }

          if (e.details) {
            throw Error(e.details);
          } else {
            await this.throwError("common.system");
          }
        }
      }
    }
    await this.throwError("get");
  };
}

const config = getConfig();
const basePath = config?.publicRuntimeConfig.API_URL || "http://localhost:8020/";

const Client = new APIClient(new Http(provider(basePath), AuthManager));

export default Client;
