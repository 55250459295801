import React, { FC } from "react";
import { Form, Formik } from "formik";
import Button from "components/button";
import TextInput from "components/input/text-input";
import Spacer from "components/layout/spacer";
import { validate } from "./validate";

export type FormValues = {
  username: string;
  password: string;
};

type Props = {
  username?: string;
  password?: string;
  onSubmit: (values: FormValues) => Promise<void>;
};

const LoginForm: FC<Props> = ({ username = "", password = "", onSubmit }) => (
  <Formik initialValues={{ username, password }} validate={validate} onSubmit={(values) => onSubmit(values)}>
    {({ isSubmitting }) => {
      return (
        <Form>
          {/* todo: translate! */}
          <TextInput placeholder="User Name" type="text" name="username" />
          <TextInput placeholder="Password" type="password" name="password" />
          <Spacer />
          <Button type="submit" layout="wide" busy={isSubmitting}>
            SIGN IN
          </Button>
        </Form>
      );
    }}
  </Formik>
);

export default LoginForm;
