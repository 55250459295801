import React, { FC, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

const H1: FC<Props> = ({ children }) => <h1 className="font-medium antialiased text-h1 tracking-wide">{children}</h1>;
const H2: FC<Props> = ({ children }) => <h2 className="font-medium antialiased text-h2 tracking-wide">{children}</h2>;
const H3: FC<Props> = ({ children }) => <h3 className="font-medium antialiased text-h3 tracking-wide">{children}</h3>;
const H4: FC<Props> = ({ children }) => <h4 className="font-medium antialiased text-h4 tracking-wide">{children}</h4>;
const XL: FC<Props> = ({ children }) => <h4 className="font-medium antialiased text-xl tracking-wide">{children}</h4>;
const XL2: FC<Props> = ({ children }) => <h4 className="font-medium antialiased text-2xl tracking-wide">{children}</h4>;

const Subtitle: FC<Props & { bold?: boolean }> = ({ bold = false, children }) => (
  <h5 className={`antialiased text-subtitle text-gray-400 tracking-wide ${bold ? "font-medium" : "font-normal"} `}>
    {children}
  </h5>
);

const P: FC<Props & { bold?: boolean }> = ({ bold = false, children }) => (
  <p className={`antialiased text-body tracking-wide ${bold ? "font-bold" : "font-normal"} `}>{children}</p>
);

export { H1, H2, H3, H4, XL, XL2, Subtitle, P };
