import React, { FC, ReactNode } from "react";
import Icon from "components/icon/icon";
import IconKind from "components/icon/icon-kind";

type Size = "small" | "medium" | "large";
type Kind = "solid" | "ghost";
type Type = "button" | "submit" | "reset";
type Layout = "wide" | "normal";

export type Props = {
  kind?: Kind;
  size?: Size;
  layout?: Layout;
  type?: Type;
  icon?: IconKind;
  disabled?: boolean;
  iconWidth?: number;
  busy?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button: FC<Props> = ({
  onClick,
  onSubmit,
  icon,
  children,
  className,
  busy = false,
  disabled = false,
  iconWidth = 26,
  type = "button",
  size = "medium",
  layout = "normal",
  kind = "solid",
}) => (
  <button
    className={`btn ${layout == "wide" ? "w-full" : "rounded-full"} flex items-center ${classNamesForKind(
      kind,
      disabled
    )} ${classNamesForSize(size)} ${className ? className : ""}`}
    role="button"
    disabled={disabled}
    onClick={onClick}
    onSubmit={onSubmit}
    type={type}
  >
    <span className={`${busy ? "loading loading-spinner" : "hidden"}`} />
    {icon && (
      <Icon
        width={iconWidth}
        data-testid="button-icon"
        className={`${disabled ? "fill-gray-300" : "fill-white"}`}
        kind={icon}
      />
    )}
    {children && <span className="font-medium antialiased tracking-wide">{children}</span>}
  </button>
);

export const classNamesForKind = (kind: Kind, disabled: boolean): string => {
  switch (kind) {
    case "solid":
      return `${
        disabled
          ? "bg-gray-100 text-gray-300 cursor-not-allowed"
          : "bg-cool-green-100 text-white hover:bg-green hover:text-white"
      }`;
    case "ghost":
      return `${
        disabled
          ? "text-gray-300 border-gray-300 cursor-not-allowed"
          : "text-green border-green hover:text-cool-green-100 hover:border-cool-green-100 hover:bg-white"
      } btn-outline bg-gray-white border border-solid`;
  }
};

export const classNamesForSize = (size: Size): string => {
  switch (size) {
    case "small":
      return "py-1.5 px-3";
    case "medium":
      return "py-2.5 px-7";
    case "large":
      return "py-2.5 px-12";
  }
};

export default Button;
