import { FormValues } from "./login-form";
import { FormikErrors } from "formik";

const validate = (values: FormValues) => {
  const errors = {} as FormikErrors<FormValues>;
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

export { validate };
