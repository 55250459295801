import React, { FC } from "react";
import { Field } from "formik";

type Props = {
  type: string;
  name: string;
  placeholder?: string;
};

const TextInput: FC<Props> = (props) => <Field {...props} className="input my-1 w-full input-bordered" />;

export default TextInput;
