import React, { FC, ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

const Container: FC<Props> = ({ children }) => (
  <div className="flex flex-col w-full h-screen justify-center items-center px-2">{children}</div>
);

export default Container;
