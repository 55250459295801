import { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

import { Manager } from "api/auth/manager";
import { APP_VERSION } from "util/version";

// This Http class wraps a provider (in this case an axios instance)
// which should be used to make network requests in the app
export class Http {
  private http: AxiosInstance;
  private auth: Manager;

  constructor(http: AxiosInstance, auth: Manager) {
    this.http = http;
    this.auth = auth;
  }

  private headers(contentType: string) {
    let headers: Record<string, string> = {
      "Content-Type": contentType,
      "Custom-User-Agent": `seller-portal/${APP_VERSION}`,
    };

    const token = this.auth.getToken();
    if (typeof token !== "undefined") {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return headers;
  }

  get<T>(
    url = "",
    params = {},
    responseType: ResponseType = "json",
    contentType = "application/json"
  ): Promise<AxiosResponse<T>> {
    const request = this.http.get<T>(url, this.config(params, contentType, responseType));
    return request;
  }

  private config(
    params = {},
    contentType = "application/json",
    responseType: ResponseType = "json"
  ): AxiosRequestConfig {
    return {
      params,
      headers: this.headers(contentType),
      responseType: responseType as ResponseType,
    };
  }

  post<T>(url = "", data = {}): Promise<AxiosResponse<T>> {
    const request = this.http.post<T>(url, data, this.config({}));
    return request;
  }

  put<T>(url = "", data = {}): Promise<AxiosResponse<T>> {
    const request = this.http.put<T>(url, data, this.config({}));
    return request;
  }

  patch<T>(url = "", data = {}): Promise<AxiosResponse<T>> {
    const request = this.http.patch<T>(url, data, this.config({}));
    return request;
  }

  delete<T>(url = ""): Promise<AxiosResponse<T>> {
    const request = this.http.patch<T>(url, this.config({}));
    return request;
  }
}
