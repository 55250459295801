import React, { FC, ReactNode } from "react";

type Direction = "horizontal" | "vertical";
type Size = "small" | "medium" | "large";

type Props = {
  size?: Size;
  direction?: Direction;
};

const sizeClass = (size: Size) => {
  switch (size) {
    case "small":
      return "h-3";
    case "medium":
      return "h-5";
    case "large":
      return "h-10";
  }
};

const Spacer: FC<Props> = ({ size = "small", direction }) => {
  return <div className={`w-full ${sizeClass(size)}`} />;
};

export default Spacer;
