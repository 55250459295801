import React, { FC } from "react";
import DarkLogo from "./logos/DarkLogo";
import LightLogo from "./logos/LightLogo";

type Theme = "light" | "dark";

type Props = {
  width?: number;
  height?: number;
  theme?: Theme;
};

const Logo: FC<Props> = ({ height = 60, theme = "light" }) => {
  return theme == "light" ? <LightLogo height={height} /> : <DarkLogo height={height} />;
};

export default Logo;
