import * as React from "react";
import { SVGProps } from "react";

const LightLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="prefix__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 557.05 220.46"
    {...props}
    style={{
      paddingRight: "15px",
    }}
  >
    <defs>
      <style>{".prefix__cls-3{stroke-width:0;fill:#fff}"}</style>
    </defs>
    <path
      d="m98.26 146.8 2.94-1.06a2.915 2.915 0 0 0 1.93-2.75v-35.82s.04-.05.06-.02l36.29 38.89c.8.85 2.02 1.15 3.12.75l2.94-1.06a2.912 2.912 0 0 0 1.93-2.74V99.3c0-1.31-.5-2.56-1.39-3.52l-20-21.38a2.895 2.895 0 0 0-3.11-.75l-2.96 1.06a2.928 2.928 0 0 0-1.94 2.75v35.82s-.04.05-.06.02l-36.3-38.89c-.8-.85-2.02-1.15-3.12-.75l-2.93 1.06a2.912 2.912 0 0 0-1.93 2.74v43.68c0 1.31.5 2.56 1.39 3.52l20.02 21.39c.8.85 2.02 1.15 3.12.75Zm26.52-64.1 15.98 17.15v37.87l-15.98-17.15V82.7Zm-44.34.04 15.98 17.15v37.87l-15.98-17.15V82.74Z"
      style={{
        fill: "#23c09c",
        strokeWidth: 0,
      }}
    />
    <path
      className="prefix__cls-3"
      d="M283.27 82.12c-20.1 0-27.97 7.07-27.97 28.11s7.87 28.11 27.97 28.11 27.97-7.07 27.97-28.11-7.71-28.11-27.97-28.11Zm0 50.25c-15.62 0-20.35-5.5-20.35-22.14s4.73-22.14 20.35-22.14 20.35 5.57 20.35 22.14-4.73 22.14-20.35 22.14ZM313.6 88.71h22.79v48.99h7.3V88.71h22.79v-5.97H313.6v5.97zM398.24 113.11 378.5 82.74h-8.01l22.32 34.11c1.19 1.86 1.82 4.02 1.82 6.23v14.62h7.3v-14.62c0-2.21.63-4.37 1.82-6.23l22.32-34.11h-8.01l-19.82 30.37ZM461.72 82.74h-28.85v54.96h29.95c16.88 0 20.49-13.58 20.49-27.72s-4-27.24-21.59-27.24Zm-1.81 48.99h-16.13c-2.98 0-3.61-.86-3.61-3.77V92.39c0-2.59.71-3.69 3.61-3.69h15.74c13.74 0 16.17 9.5 16.17 21.28s-2.04 21.75-15.78 21.75ZM235.62 82.74v45.43l-40.93-44.89a3.13 3.13 0 0 0-3.36-.81l-3.16 1.14a3.153 3.153 0 0 0-2.08 2.97v51.13h7.22V92.28l40.93 44.89a3.13 3.13 0 0 0 3.36.81l3.16-1.14a3.153 3.153 0 0 0 2.08-2.97V82.74h-7.22Z"
    />
    <path
      style={{
        strokeWidth: 0,
        fill: "none",
      }}
      d="M0 0h557.05v220.46H0z"
    />
  </svg>
);

export default LightLogo;
