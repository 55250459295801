import Card from "components/layout/card";
import Container from "components/layout/container";
import Logo from "components/logo";
import { NextPage, NextPageContext } from "next";
import Router, { useRouter } from "next/router";
import Spacer from "components/layout/spacer";
import { H4 } from "components/typography";
import LoginForm, { FormValues } from "components/form/login/login-form";
import BackdropGradient from "components/layout/backdrop-gradient";
import Client from "api/client";
import Alert from "components/display/alert";
import { useEffect, useState } from "react";
import { DocumentHead } from "components/page/document-head";
import useTranslation from "next-translate/useTranslation";
import { redirectIfLoggedIn } from "api/auth/redirectIfLoggedIn";

const Login: NextPage = () => {
  const router = useRouter();
  const { locale } = router;
  const { t } = useTranslation();
  const [alert, setAlert] = useState({ visible: false, message: "" });

  const login = async (values: FormValues) => {
    try {
      await Client.login(values.username, values.password, locale);
      await Router.push("/");
    } catch (e) {
      setAlert({
        visible: true,
        message: (e as Error).message,
      });
    }
  };

  useEffect(() => {
    const { status } = router.query;
    if (status == "please login") {
      setAlert({
        visible: true,
        message: t("login:alert"),
      });
    }
  }, [router.query]);

  return (
    <>
      <DocumentHead title={t("common:documentHead.login")} />
      <BackdropGradient>
        <Container>
          <Alert visible={alert.visible} message={alert.message} />
          <Card alignment="center">
            <Logo theme="dark" />
            <Spacer />
            {/* todo: translate! */}
            <H4>Sign In</H4>
            <Spacer />
            <LoginForm onSubmit={login} />
          </Card>
        </Container>
      </BackdropGradient>
    </>
  );
};

export async function getServerSideProps(ctx: NextPageContext) {
  return redirectIfLoggedIn(ctx);
}

export default Login;
